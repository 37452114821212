import {
    faFile,
    faHeartPulse,
    faCircleQuestion,
    faHandHoldingMedical,
    faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipProps } from '@mui/material';

// Resuscitation Strategies

export const ResuscitationStrategiesDnrChip: ChipProps = {
    color: 'black',
    variant: 'filled',
    icon: <FontAwesomeIcon icon={faFile} />,
};

export const ResuscitationStrategiesCprChip: ChipProps = {
    color: 'primary',
    variant: 'outlined',
    icon: <FontAwesomeIcon icon={faHeartPulse} />,
};

export const ResuscitationStrategiesUnknownChip: ChipProps = {
    color: 'errorInverse',
    variant: 'filled',
    icon: <FontAwesomeIcon icon={faCircleQuestion} />,
};

// Hospice Status

export const HospiceStatusHospiceCareChip: ChipProps = {
    color: 'primaryInverse',
    icon: <FontAwesomeIcon icon={faHandHoldingMedical} />,
};

export const HospiceStatusStandardCareChip: ChipProps = {
    color: 'primaryInverse',
};

export const HospiceStatusUnknownChip: ChipProps = {
    color: 'errorInverse',
    icon: <FontAwesomeIcon icon={faCircleQuestion} />,
};

// Contacts
export const ContactCardPowerOfAttorneyChip: ChipProps = {
    color: 'black',
    icon: <FontAwesomeIcon icon={faHandHoldingMedical} />,
};

export const ContactCardIsLocalChip: ChipProps = {
    color: 'primary',
    variant: 'outlined',
    icon: <FontAwesomeIcon icon={faLocationDot} />,
};
