import cronstrue from 'cronstrue';
import {
    format,
    isToday,
    isYesterday,
    differenceInMilliseconds,
    fromUnixTime,
    differenceInSeconds,
    parseISO,
    getTime,
    isTomorrow,
    differenceInMinutes,
    formatDistanceToNowStrict,
    isPast,
} from 'date-fns';
import { toZonedTime, fromZonedTime, format as zonedFormat } from 'date-fns-tz';

const OneYearInSeconds = 31536000;
const OneMonthInSeconds = 2592000;
const OneDayInSeconds = 86400;

export const ThirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1_000; // thirty days in ms

export const HumanReadableDateTime = (timestamp, timezone) => {
    const datetime = toZonedTime(timestamp, timezone);
    if (isToday(datetime)) {
        return `Today at ${format(datetime, 'h:mm a')}`;
    }

    if (isYesterday(datetime)) {
        return `Yesterday at ${format(datetime, 'h:mm a')}`;
    }

    return `${format(datetime, 'MMM dd, yyyy')} at ${format(datetime, 'h:mm a')}`;
};

export const DaysAgo = (timestamp) => {
    const now = new Date();
    const secondsDiff = differenceInSeconds(now, timestamp);

    // 1 year+
    if (secondsDiff > OneYearInSeconds) {
        const years = Math.floor(secondsDiff / OneYearInSeconds);
        const days = Math.round((secondsDiff % OneYearInSeconds) / OneDayInSeconds);
        return `${years}y ${days}d`;
    }

    // 1 month to 1 year
    if (secondsDiff > OneMonthInSeconds && secondsDiff <= OneYearInSeconds) {
        return `${Math.round(secondsDiff / OneMonthInSeconds)}mo`;
    }

    // 1 day to 1 month
    if (secondsDiff > OneDayInSeconds && secondsDiff <= OneMonthInSeconds) {
        return `${Math.round(secondsDiff / OneDayInSeconds)}d`;
    }

    // less than 1 day
    return `less than 1d`;
};

export const TimeAgo = (timestampInSeconds, showSeconds = false) => {
    const datetime = fromUnixTime(timestampInSeconds);
    const now = new Date();
    const secondsDiff = differenceInSeconds(now, datetime);

    // 1 year+
    if (secondsDiff > OneYearInSeconds) {
        const years = Math.floor(secondsDiff / OneYearInSeconds);
        const days = Math.round((secondsDiff % OneYearInSeconds) / OneDayInSeconds);
        return `${years}y ${days}d`;
    }

    // 1 day to 1 year
    if (secondsDiff > OneDayInSeconds && secondsDiff <= OneYearInSeconds) {
        return `${Math.round(secondsDiff / OneDayInSeconds)}d`;
    }

    // 1 hour to 1 day
    if (secondsDiff > 3600 && secondsDiff <= OneDayInSeconds) {
        const hours = Math.floor(secondsDiff / 3600);
        const minutes = Math.round((secondsDiff % 3600) / 60);

        if (minutes === 60) {
            return `${hours + 1}h`;
        }
        return `${hours}h ${minutes}m`;
    }

    // 1 minute to 1 hour
    if (secondsDiff > 60 && secondsDiff <= 3600) {
        return `${Math.round(secondsDiff / 60)}m`;
    }

    // A minute or less
    if ((showSeconds && secondsDiff === 0) || (!showSeconds && secondsDiff <= 60)) {
        return 'now';
    }

    return `${secondsDiff}s`;
};

export const TimeUntil = (timestamp) => {
    const now = new Date();
    const secondsDiff = differenceInSeconds(fromUnixTime(timestamp), now);

    // 1 year+
    if (secondsDiff > OneYearInSeconds) {
        const years = Math.floor(secondsDiff / OneYearInSeconds);
        const days = Math.round((secondsDiff % OneYearInSeconds) / OneDayInSeconds);
        return `${years}y ${days}d`;
    }

    // 1 day to 1 year
    if (secondsDiff > OneDayInSeconds && secondsDiff <= OneYearInSeconds) {
        return `${Math.round(secondsDiff / OneDayInSeconds)}d`;
    }

    // 1 hour to 1 day
    if (secondsDiff > 3600 && secondsDiff <= OneDayInSeconds) {
        const hours = Math.floor(secondsDiff / 3600);
        const minutes = Math.round((secondsDiff % 3600) / 60);

        if (minutes === 60) {
            return `${hours + 1}h`;
        }
        return `${hours}h ${minutes}m`;
    }

    // 1 minute to 1 hour
    if (secondsDiff > 60 && secondsDiff <= 3600) {
        return `${Math.round(secondsDiff / 60)}m`;
    }

    // A minute or less
    if (secondsDiff <= 60) {
        return 'now';
    }

    return secondsDiff;
};

export const GetRecurrenceString = (cronScheduleExpression) => {
    if (cronScheduleExpression) {
        // If it's daily, the last character of the cron will be an asterisk
        if (cronScheduleExpression.endsWith('*')) {
            return 'Daily';
        }

        const parsedString = cronstrue.toString(cronScheduleExpression);

        // Split out the time
        const cronDays = parsedString.split(',');
        cronDays.shift();

        const formattedString = 'Weekly on'.concat(cronDays).replace(' only on', '');
        return formattedString;
    }
    return null;
};

export const GetTimeDisplay = (hour, minute) => {
    const computedHour = minute === 60 ? (hour + 1) % 24 : hour;
    const computedMinute = minute === 60 ? 0 : minute;

    const paddedMinute = String(computedMinute).padStart(2, '0');
    if (computedHour === 0) {
        return `12:${paddedMinute} am`;
    }
    if (computedHour > 12) {
        return `${computedHour - 12}:${paddedMinute} pm`;
    }
    if (computedHour === 12) {
        return `${computedHour}:${paddedMinute} pm`;
    }
    return `${computedHour}:${paddedMinute} am`;
};

export const GetStartTime = (cronExpression) => {
    const cronComponents = cronExpression.split(' ');
    if (cronComponents.length > 2) {
        const hour = parseInt(cronComponents[1], 10);
        if (!Number.isNaN(hour)) {
            return GetTimeDisplay(hour, cronComponents[0]);
        }
    }
    return 'N/A';
};

export const GetEndTime = (cronExpression, durationInMinutes) => {
    const cronComponents = cronExpression.split(' ');
    if (cronComponents.length > 2) {
        const minute = parseInt(cronComponents[0], 10);
        const hour = parseInt(cronComponents[1], 10);
        const additionalMinutes = durationInMinutes % 60;
        const additionalHours = Math.floor((minute + durationInMinutes) / 60);
        if (!Number.isNaN(hour)) {
            return GetTimeDisplay((hour + additionalHours) % 24, (minute + additionalMinutes) % 60);
        }
    }
    return 'N/A';
};

export const HourTo24 = (hour, amOrPm) => {
    let transformedHour = hour;
    const hourInt = Number(hour);

    if (hour.charAt(0) === '0') {
        transformedHour = transformedHour.substring(1);
    }
    if (amOrPm === 'am' && hourInt === 12) {
        transformedHour = '0';
    }
    if (amOrPm === 'pm' && hourInt < 12) {
        transformedHour = hourInt + 12;
    }
    return transformedHour;
};

export const HourTo12 = (hour) => {
    let transformedHour = hour;

    if (hour.length > 1 && hour.charAt(0) === '0') {
        transformedHour = transformedHour.substring(1);
    }

    const hourInt = Number(transformedHour);

    if (transformedHour === '0') {
        transformedHour = '12';
    }
    if (hourInt > 12) {
        transformedHour = hourInt - 12;
    }
    return transformedHour;
};

export const TimeSince = (startTime) => {
    // Convert the start time to ISO8601
    const isoDate = startTime.replace(' ', 'T').concat('Z');
    const startDate = parseISO(isoDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    const now = Date.now();
    return differenceInMilliseconds(now, startDate);
};

export const TrimmedDate = (dateString) => {
    // Trim timezone from API date string and return a JS date
    let reducedString;

    if (dateString.includes('[')) {
        reducedString = dateString.substring(0, dateString.indexOf('['));
    } else if (dateString.includes('Z')) {
        reducedString = dateString.substring(0, dateString.indexOf('Z'));
    }

    return new Date(reducedString || dateString);
};

export const ComputedFutureTimeInMs = (scheduledDateTime, facilityTimezone) => {
    if (scheduledDateTime && facilityTimezone) {
        const utcTime = fromZonedTime(scheduledDateTime, facilityTimezone);
        return getTime(utcTime);
    }
    return null;
};

export const ComputedDurationInMinutes = (urgency) => {
    switch (urgency) {
        case 0:
            return 15;
        case 1:
            return 60;
        case 2:
            return 1440;
        case 3:
            return undefined;
        default:
            return 1440;
    }
};

export const ComputedUrgencyfromMinutes = (minutes) => {
    if (!minutes) {
        return 3;
    }

    switch (minutes) {
        case 15:
            return 0;
        case 60:
            return 1;
        default:
            return 2;
    }
};

export const IsOverdue = (dueDateAndTime) => {
    if (dueDateAndTime) {
        const dueDate = TrimmedDate(dueDateAndTime);
        if (dueDate) {
            return isPast(dueDate);
        }
    }
    return false;
};

export const DueDateAndTime = (dueDateAndTime, timezone) => {
    if (!dueDateAndTime || !timezone) {
        return null;
    }

    // Get the localized datetime for calculating time diffs
    const dueDate = TrimmedDate(dueDateAndTime);

    // Get the datetime in the facility time zone
    const zonedDate = toZonedTime(dueDate, timezone);

    // Format the facility time
    const formattedZonedDate = zonedFormat(zonedDate, 'h:mm aa');

    // Is the task due tomorrow?
    const dueString = isTomorrow(dueDate)
        ? `Tomorrow at ${formattedZonedDate}`
        : formattedZonedDate;

    // How many minutes difference between the due date and now
    const minutesDifference = differenceInMinutes(dueDate, new Date());

    // Get the difference between due date and now in words
    const distanceString = formatDistanceToNowStrict(dueDate, {
        addSuffix: false,
    });

    const dueTimeString = minutesDifference > 60 ? dueString : `in ${distanceString}`;

    return IsOverdue(dueDateAndTime) ? `by ${distanceString}` : dueTimeString;
};

const TimeUnitsAbbreviations = {
    second: 's',
    minute: 'm',
    hour: 'h',
    day: 'd',
    week: 'w',
    month: 'mo',
    year: 'y',
};

export const abbreviateDistance = (
    distance,
    preserveSpace = false,
    abbreviations = TimeUnitsAbbreviations
) => {
    /**
     * Creates a regex string from the keys of the abbreviations object
     *   - `preserveSpace == true`: "second[s]?|minute[s]?|hour[s]?|day[s]?|week[s]?|month[s]?|year[s]?")
     *   - `preserveSpace == false`: " second[s]?| minute[s]?| hour[s]?| day[s]?| week[s]?| month[s]?| year[s]?"
     */
    const regexSearchString = Object.keys(abbreviations)
        .map((unit) => (preserveSpace ? `${unit}[s]?` : ` ${unit}[s]?`))
        .join('|');
    const regex = new RegExp(regexSearchString, 'gi');

    return distance.replace(
        regex,
        (matched) =>
            // singular case (i.e. "minute")
            abbreviations[matched.toLowerCase().trim()] ||
            // plural case (i.e. "minutes")
            abbreviations[
                matched
                    .substr(0, matched.length - 1)
                    .toLowerCase()
                    .trim()
            ]
    );
};

export const formatTimeDurationFromMinutes = (minutesPassed) => {
    if (!minutesPassed) {
        return '0s';
    }
    const days = Math.floor(minutesPassed / (24 * 60));
    const hours = Math.floor((minutesPassed % (24 * 60)) / 60);

    const totalMinutes = minutesPassed % 60;
    const minutes = Math.floor(totalMinutes);
    const seconds = Math.round((totalMinutes - minutes) * 60);

    return [
        days ? `${days}d` : '',
        hours ? `${hours}h` : '',
        minutes ? `${minutes}m` : '',
        seconds ? `${seconds}s` : '',
    ]
        .filter((element) => !!element)
        .join(' ');
};
